<template>
  <div class="fill-height background">
    <NavigationDrawer :drawer="drawer" @closeDrawer="closeDrawer" />
    <AppBar @switchDrawer="switchDrawer" />
    <v-main>
      <router-view></router-view>
    </v-main>
  </div>
</template>

<script>
export default {
  name: "Interface",
  components: {
    AppBar: () => import("../../layout/AppBar"),
    NavigationDrawer: () => import("../../layout/NavigationDrawer"),
  },
  data: () => ({
    title: "",
    drawer: false,
  }),
  mounted() {},
  methods: {
    switchDrawer() {
      this.drawer = !this.drawer;
    },
    closeDrawer() {
      this.drawer = false;
    },
  },
};
</script>

<style></style>
